.wrapper-all-content {
	background-color: #fff;
	position: relative;
	width: 100%;
}

.hp-about {
	padding-bottom: 80px;
	@include respond-to('1100') {
		padding-bottom: 60px;
	}
	@include respond-to('medium') {}
	@include respond-to('small') {}
	@include respond-to('extra-small') {}
	font-size: 0;
	& .section-limit {
		position: relative;
	}
	&__left {
		float: $left;
		// display: inline-block;
		vertical-align: bottom;
		width: 50%;
		padding-top: 100px;
		padding-bottom: 70px;
		font-size: initial;
		@include respond-to('1100') {
			padding-top: 60px;
			padding-bottom: 60px;
		}
		@include respond-to('1100') {
			width: 55%;
		}
		@include respond-to('medium') {
			width: 100%;
			padding-top: 40px;
			padding-bottom: 40px;
		}
		@include respond-to('small') {
			padding-top: 30px;
			padding-bottom: 30px;
		}
	}
	&__right {
		float: $right;
		// display: inline-block;
		vertical-align: bottom;
		width: 50%;
		font-size: initial;
		@include respond-to('1100') {
			width: 45%;
		}
		@include respond-to('medium') {
			width: 100%;
		}
	}
	&__title {
		position: relative;
		margin-bottom: 40px;
		padding-top: 25px;
		font-size: 30px;
		font-weight: 900;
		color: #000;
		line-height: 1;
		text-align: $right;
		@include respond-to('medium') {
			margin-bottom: 30px;
		}
		@include respond-to('small') {
			margin-bottom: 20px;
		}
		& span {
			display: inline-block;
			position: relative;
			&::after {
				content: '';
				position: absolute;
				left: 0;
				right: 0;
				margin: 0 auto;
				top: -25px;
				width: 50px;
				height: 1px;
				background-color: #000;
			}
			&::before {
				content: '';
				position: absolute;
				left: 0;
				right: 0;
				margin: 0 auto;
				top: -20px;
				width: 40px;
				height: 1px;
				background-color: #000;
			}
		}
	}
	&__text {
		font-size: 18px;
		font-weight: 400;
		color: #000;
		line-height: 1.1;
		@include respond-to('extra-small') {
			text-align: justify;
		}
	}
	&__photo {
		text-align: $right;
		padding-#{$right}: 50px;
		@include respond-to('1100') {
			padding-#{$right}: 20px;
		}
		@include respond-to('extra-small') {
			padding-#{$right}: 0px;
		}
		&::after {
			content: '';
			position: absolute;
			#{$left}: 0;
			bottom: 0;
			width: 150%;
			height: 2px;
			background-color: #000;
		}
	}
	&__img {
		position: relative;
		display: inline-block;
		// &::after {
		// 	content: '';
		// 	position: absolute;
		// 	#{$right}: 0;
		// 	bottom: 0;
		// 	height: 297px;
		// 	width: 2px;
		// 	background-color: #000;
		// }
		// &::before {
		// 	content: '';
		// 	position: absolute;
		// 	#{$left}: if-rtl(-60px, 0);
		// 	bottom: 0;
		// 	height: 232px;
		// 	width: 2px;
		// 	background-color: #000;
		// 	@include respond-to('1100') {
		// 		#{$left}: -20px;
		// 	}
		// 	@include respond-to('extra-small') {
		// 		#{$left}: 0px;
		// 	}
		// }
	}
}

.homepage-boxes {
	background-color: #fff;
	&_white {
		padding-bottom: 50px;
		background-color: #fff;
		@include respond-to('extra-small') {
			padding-bottom: 25px;
		}
		& .home-buttons {
			// box-shadow: 0 0 0 2px rgba($color: #fff, $alpha: 1.0);
		}
	}
	&__item {
		float: $right;
		width: 50%;
		height: 600px;
		padding: 1px;
		@include respond-to('huge') {
			height: 500px;
		}
		@include respond-to('large') {
			height: 450px;
		}
		@include respond-to('medium') {
			height: 350px;
		}
		@include respond-to('small') {
			height: 270px;
		}
		@include respond-to('extra-small') {
			width: 100%;
			height: 250px;
		}
		&:nth-child(5n+3),
		&:nth-child(5n+4),
		&:nth-child(5n+5) {
			width: 33.33%;
			height: 450px;
			@include respond-to('huge') {
				height: 400px;
			}
			@include respond-to('larger') {
				height: 350px;
			}
			@include respond-to('larger') {
				height: 300px;
			}
			@include respond-to('small') {
				width: 50%;
				height: 270px;
			}
			@include respond-to('extra-small') {
				width: 100%;
				height: 250px;
			}
		}
	}
}