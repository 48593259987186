.horizontal-form {
	background-color: #000;
	position: relative;
	z-index: 1;
	padding-top: 80px;
	padding-bottom: 60px;
	@include respond-to('larger') {
		padding-top: 60px;
		padding-bottom: 50px;
	}
	@include respond-to('medium') {
		padding-top: 50px;
		padding-bottom: 40px;
	}
	@include respond-to('small') {
		padding-top: 40px;
		padding-bottom: 30px;
	}
	@include respond-to('extra-small') {
		padding-top: 30px;
		padding-bottom: 20px;
	}
}

.form-horizontal {
	max-width: 1130px;
	margin: 0 auto;
	& .contact-us-form {
		&__title {
			margin-bottom: 40px;
			font-size: 30px;
			font-weight: 400;
			color: $title;
			line-height: 0.9;
			text-align: center;
			& span {
				display: block;
				font-size: 20px;
				font-weight: 300;
				color: $title;
				line-height: 0.9;
				text-align: center;
			}
		}
	}
	& .contact-wrap-input {
		// max-width: 1100px;
		width: 100%;
		margin: 0 auto;
		@include respond-to('larger') {
			font-size: 0;
			text-align: center;
		}
	}
	& .form-group {
		float: $right;
		vertical-align: top;
		margin: 0 0 0 20px;
		max-width: 290px;
		width: 100%;
		@include respond-to('larger') {
			// max-width: 260px;
			float: none;
			display: inline-block;
			margin: 0;
			padding: 0 8px;
			font-size: initial;
		}
		@include respond-to('1100') {
			// max-width: 23%;
		}
		@include respond-to('medium') {
			// max-width: 50%;
		}
		@include respond-to('extra-small') {
			padding: 0 4px;
			// max-width: 100%;
		}
		// &_check {
		// 	max-width: 108px;
		// 	@include respond-to('larger') {
		// 		max-width: 40%;
		// 		margin: 0;
		// 	}
		// 	@include respond-to('extra-small') {
		// 		margin-top: 2px;
		// 		max-width: 44%;
		// 	}
		// }
		&_ml {
			margin-#{$left}: 0;
		}
		&_button {
			max-width: 170px;
			float: $left;
			margin: 0;
			@include respond-to('larger') {
				display: block;
				margin: 10px auto 0;
				// max-width: 240px;
				width: 100%;
				float: none;
			}
		}
	}
	& .newslatter-input {
		height: 60px;
		width: 100%;
		padding: 11px 15px 10px;
		background-color: #2f2f2f;
		color: $accent;
		font-weight: 400;
		font-size: 20px;
		border: none;
		// border-bottom: 1px solid #c5c5c5;
		border-radius: 0;
		box-shadow: none;
		@include respond-to('extra-small') {
			padding: 14px 10px 13px;
		}
		&::placeholder {
			font-size: 20px;
			color: $accent;
			font-weight: 400;
		}
	}
	& .newslatter-btn {
		position: relative;
		width: 100%;
		margin: 0;
		height: 60px;
		font-size: 18px;
		font-weight: 600;
		background-color: $accent;
		border: none;
		color: #000;
		@include respond-to('larger') {
			&:active {
				@include btn-effect-active();
			}
		}
		&_decor {
			&::after {
				z-index: -1;
				content: '';
				position: absolute;
				#{$right}: 100%;
				top: 0;
				width: 500px;
				height: 60px;
				background-color: #fff;
				@include respond-to('larger') {
					display: none;
				}
			}
		}
	}
}

.help-block {
	font-size: 16px;
	color: #fff !important;
}

.form-control:focus {
	border-radius: 0;
}