.wrapper-banners {
	position: relative;
	width: 100%;
	height: 850px;
	overflow: hidden;
	@include respond-to('huge') {
		height: 800px;
	}
	@include respond-to('larger') {
		height: 750px;
	}
	@include respond-to('large') {
		height: 650px;
	}
	@include respond-to('medium') {
		height: 550px;
	}
	@include respond-to('small') {
		height: 450px;
	}
	@include respond-to('extra-small') {
		height: 400px;
	}
	@include respond-to('mobile-l') {
		height: 350px;
	}
}

.wrap-banner-scroll {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	@include respond-to('medium') {
		position: static;
	}
}

.banner-item {
	width: 100%;
	min-height: 850px;
	position: relative;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	@include respond-to('huge') {
		min-height: 800px;
	}
	@include respond-to('larger') {
		min-height: 750px;
	}
	@include respond-to('large') {
		min-height: 650px;
	}
	@include respond-to('medium') {
		min-height: 550px;
	}
	@include respond-to('small') {
		min-height: 450px;
	}
	@include respond-to('extra-small') {
		min-height: 400px;
	}
	@include respond-to('mobile-l') {
		min-height: 350px;
	}
}