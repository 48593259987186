.newspapers {
	padding: 80px 0;
	margin: 0 -13px;
	font-size: 0;
	@include respond-to('larger') {
		padding: 60px 0;
	}
	@include respond-to('medium') {
		padding: 40px 0;
	}
	@include respond-to('small') {
		padding: 35px 0;
		max-width: 535px;
		margin: 0 auto;
	}
	@include respond-to('extra-small') {
		padding: 25px 0;
	}
	&__item {
		display: inline-block;
		vertical-align: top;
		width: 25%;
		font-size: initial;
		padding: 13px;
		@include respond-to('large') {
			width: 33.33%;
		}
		@include respond-to('medium') {
			padding: 8px;
		}
		@include respond-to('small') {
			width: 50%;
		}
		@include respond-to('extra-small') {
			width: 100%;
		}
	}
}

.newspaper-box {
	display: block;
	border: 1px solid #000;
	padding: 13px;
	max-width: 280px;
	margin: 0 auto;
	background-color: #fff;
	box-shadow: 0 0 15px 0 rgba($color: #000000, $alpha: 0.1);
	@include respond-to('medium') {
		max-width: 250px;
	}
	&:hover {
		box-shadow: 0 0 15px 0 rgba($color: #000000, $alpha: 0.3);
	}
	&__img {
		display: block;
		& img {
			width: 100%;
			height: 352px;
			object-fit: cover;
			object-position: center;
			@include respond-to('medium') {
				height: 300px;
			}
		}
	}
}
