footer {
	width: 100%;
	position: relative;
	z-index: 1;
	background-color: #000;
}

.ftr-wrap {
	border-top: 1px solid #2f2f2f;
	padding: 70px 0 5px;
	@include respond-to('large') {
		padding: 45px 0 5px;
	}
	@include respond-to('medium') {
		padding: 25px 0 5px;
	}
	&__bottom {
		position: relative;
		padding: 90px 0 30px;
		@include respond-to('large') {
			padding: 40px 0 30px;
		}
		@include respond-to('medium') {
			padding: 20px 0 10px;
		}
	}
}

.ftr-wrap-nav {
	font-size: 0;
	max-width: 1100px;
	margin: 0 auto;
	&__item {
		display: inline-block;
		width: 33.33%;
		font-size: initial;
		@include respond-to('extra-small') {
			width: 50%;
		}
	}
}

.ftr-logo {
	position: relative;
	text-align: center;
}

.ftr-info {
	position: relative;
	font-size: 16px;
	font-weight: 400;
	color: #000;
	// padding-#{$left}: 180px;
	@include respond-to('small') {
		text-align: center;
		padding: 0;
	}
	@include respond-to('extra-small') {
		font-size: 16px;
	}
	& p {}
}

.ftr-cont {
	position: absolute;
	#{$right}: 0;
	top: 75%; 
	transform: translateY(-50%);
	@include respond-to('medium') {
		position: static;
		transform: translateY(0);
		text-align: center;
		margin-top: 20px;
	}
	&__soc {
		display: inline-block;
		margin-#{$right}: 20px;
		& a {
			@include link_no-hover(#fff);
			margin: 0 3px;
		}
	}
	&__phone {
		display: inline-block;
		& a {
			@include link_no-hover(#fff);
			& i {
				margin-#{$left}: 10px;
			}
		}
	} 
}
.ftr-cont-adress {
	position: absolute;
	#{$right}: 0;
	top: 59%; 
	color: #fff;
	transform: translateY(-50%);
	@include respond-to('medium') {
		position: relative;
		text-align: center;
		margin-top: 15px;
	}	
}
.site-footer__credit-holder {
	position: absolute;
	padding-top: 15px;
	#{$left}: 0;
	top: 60%;
	transform: translateY(-50%);
	@include respond-to('medium') {
		position: static;
		margin-top: 0px;
		transform: translateY(0);
	}
}