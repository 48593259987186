.main-header {
	width: 100%;
	z-index: 99;
	position: fixed;
	top: 0;
	background: none;
	transition: all 0.4s ease;
}

.header-desktop {
	padding-top: 1px;
	height: 160px;
	text-align: center;
	background-color: rgba($color: #000000, $alpha: 0.9);
	box-shadow: 0 0 15px 2px rgba($color: #000000, $alpha: 0.3);
	@include respond-to('medium') {
		display: none;
	}
}

.logo {
	margin-top: 39px;
	text-align: center;
	& a {
		& > img {
			max-height: 43px;
			max-width: 271px;
			width: 100%;
		}
	}
}

.languages {
	display: inline-block;
	vertical-align: top;
	margin-top: 28px;
	min-width: 120px;
	margin-#{$right}: 70px;
	& > ul {
		list-style: none;
		margin: 0 auto;
		padding: 0;
		display: inline;
		& > li {
			float: $right;
			margin-#{$left}: 3px;
			margin-top: -3px;
			position: relative;
			&:last-child {
				& > a {
					&:after {
						background: transparent;
					}
				}
			}
			& > a {
				font-weight: 300;
				font-size: 16px;
				color: #808080;
				display: block;
				border: 1px solid transparent;
				&:hover {
					text-decoration: none;
					color: #fff;
				}
				&:focus,
				&:active {
					text-decoration: none;
					color: #fff;
				}
				&:after {
					content: "";
					display: inline-block;
					height: 15px;
					width: 1px;
					background: #808080;
					margin: side-values(0 4px -3px 1px);
				}
				&.active {
					color: #fff;
				}
			}
		}
	}
}

.social {
	display: inline-block;
	vertical-align: top;
	text-align: $left;
	margin-top: 24px;
	margin-#{$left}: 70px;
	min-width: 100px;
	@include respond-to('small') {
		display: block;
		margin: 20px auto;
		text-align: center;
	}
	& a {
		padding-#{$right}: 8px;
		@include link_no-hover(#fff);
		font-size: 19px;
	}
}

.main-navi {
	display: inline-block;
	vertical-align: top;
	& > ul {
		display: block;
		list-style: none;
		margin: 8px 0 0;
		padding: 0;
		position: relative;
		font-size: 0;
		& > li {
			position: relative;
			display: inline-block;
			margin-#{$left}: 0px;
			padding: 10px 0 24px;
			font-size: initial;
			&:hover {
				& .drop-menu {
					transform: scaleY(1) translateX(-50%);
					max-height: 100vh;
					opacity: 1;
				}
				& > a {
					color: #fff;
				}
				& .drop-menu + a {
					&::before {
						opacity: 1;
					}
				}
			}
			& > a {
				position: relative;
				font-size: 20px;
				color: $accent;
				padding: 3px 14px;
				display: block;
				font-weight: 400;
				&:hover {
					text-decoration: none;
				}
				&:focus,
				&:active {
					text-decoration: none;
				}
				&.active {
					text-decoration: none;
					color: #fff;
				}
				&::before {
					content: '';
					position: absolute;
					z-index: -1;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 10px 10px 10px;
					border-color: transparent transparent rgba($color: #000000, $alpha: 0.5) transparent;
					top: 35px;
					left: 50%;
					transform: translateX(-50%);
					pointer-events: none;
					opacity: 0;
				}
			}
		}
	}
}

.drop-menu {
	position: absolute;
	left: 50%;  
	// right: 0%;
	top: 68px;
	transform: scaleY(0) translateX(-50%);
	max-height: 0;
	min-width: 250px;
	width: 100%;
	opacity: 0;
	margin: 0 auto;
	transform-origin: top;
	transition: all 0.3s ease;
	background-color: rgba($color: #000, $alpha: 0.9);
	border-top: 1px solid #fff;
	& ul {
		position: relative;
		width: 100%;
		height: 100%;
		padding: 5px 27px;
		list-style: none;
		margin: 0;
	}
	& li {
		display: block;
		border-bottom: 1px solid #505050;
		transition: border 0.2s ease;
		&:last-child {
			border-bottom: none;
			& a {}
		}
		&:hover {
			border-bottom: 1px solid #fff;
		}
		& a {
			position: relative;
			display: block;
			flex: 1 1 100%;
			padding: 27px 5px;
			color: #fff;
			text-align: center;
			font-size: 20px;
			font-weight: 400;
			line-height: 0.9;
			@include respond-to('large') {
				// font-size: 20px;
				padding: 10px 0px;
			}
			&:hover {
				text-decoration: none;
				color: $accent !important;
			}
			&:focus,
			&:active {
				text-decoration: none;
			}
		}
	}
}

.header-mobile {
	display: none;
	height: 70px;
	background-color: rgba($color: #000000, $alpha: 1);
	@include respond-to('medium') {
		display: block;
	}
	&__logo {
		margin-#{$left}: 15px;
		margin-top: 14px;
		float: $left;
		@include respond-to('extra-small') {
			margin-top: 22px;
		}
		& > img {
			// width: 80px;
			// height: 80px;
			@include respond-to('extra-small') {
				max-width: 170px;
			}
		}
	}
	&__tel {
		display: inline-block;
		margin-top: 17px;
		i {
			color: #fff;
			font-size: 32px;
		}
	}
}

// .main-header.scrolling {
// 	.header-desktop {
// 		background-color: $text;
// 		height: 80px;
// 	}
// 	.main-navi {
// 		float: $right;
// 		& > ul {
// 			margin-top: 15px;
// 		}
// 	}
// 	.logo {
// 		margin-#{$left}: 15px;
// 		margin-top: 1px;
// 		float: $left;
// 		& > a {
// 			& > img {
// 				max-height: 75px;
// 				max-width: 55px;

// 			}
// 		}
// 	}
// 	.header-mobile {
// 		background-color: $text;
// 		&__logo {
// 			margin-top: 2px;
// 			margin-#{$left}: 5px;
// 			& img {
// 				max-height: 65px;
// 				width: auto;
// 			}
// 		}
// 	}
// }
