.wrap-content {
	padding: 90px 0;
	@include respond-to('larger') {
		padding: 60px 0;
	}
	@include respond-to('medium') {
		padding: 40px 0;
	}
	@include respond-to('small') {
		padding: 35px 0;
	}
	@include respond-to('extra-small') {
		padding: 25px 0;
	}
	&_p0 {
		padding: 0;
	}
}

.title {
	font-size: 40px;
	font-weight: 900;
	color: #fff;
	line-height: 1;
	@include respond-to('medium') {
		font-size: 30px;
	}
	@include respond-to('small') {
		font-size: 25px;
	}
}

// .hr-content {
// 	border-top: 1px solid #d9d9d9;
// 	margin: 0 0 20px;
// }

.content-text {
	text-align: justify;
	&_pl {}
	&_pr {
		padding-#{$right}: 35px;
		@include respond-to('large') {
			padding-#{$right}: 15px;
		}
		@include respond-to('medium') {
			padding-#{$right}: 0;
		}
	}
	h2,
	h3 {
		margin: 0 0 35px;
		font-weight: 600;
		font-size: 25px;
		color: #000;
		@include respond-to('1100') {
			font-size: 22px;
			margin-bottom: 20px;
		}
		@include respond-to('small') {
			font-size: 20px;
		}
	}
	p {
		font-weight: 400;
		font-size: 18px;
		color: #000;
		// margin-bottom: 25px;
		@include respond-to('small') {
			font-size: 16px;
			// margin-bottom: 15px;
		}
	}
	iframe {
		@include respond-to('small') {
			text-align: center !important;
			margin: 15px auto !important;
			width: 100% !important;
			height: auto !important;
			float: none !important;
			display: block !important;
		}
	}
	img {
		@include respond-to('small') {
			float: none !important;
			text-align: center !important;
			width: auto !important;
			height: auto !important;
			margin: 15px auto !important;
		}
	}
}